<template lang="">
  <interview-detail :is-create-form="true" />
</template>
<script>
import InterviewDetail from './components/InterviewDetail.vue'
export default {
  name: 'CreateInterview',
  components: { InterviewDetail }
}
</script>
<style lang="">

</style>
